<template>
  <live-map :tags="Tags" />
</template>

<script>
import { mapState, mapActions } from "vuex";
import LiveMap from "../leaflet/LiveMap.vue";
import { tagsWithAttributeDict } from "../../_helpers/tagHelper";

export default {
  name: "TagMap",

  data() {
    return {
      tags: [],
    };
  },

  components: {
    LiveMap,
  },

  computed: {
    ...mapState("users", ["currentUser", "userCompany", "hasCompany"]),

    Tags() {
      return tagsWithAttributeDict(this.tags);
    },
  },

  methods: {
    ...mapActions("tag", { getTags: "getTagsWithInformation" }),
  },

  async created() {
    this.tags = await this.getTags();
  },
};
</script>
